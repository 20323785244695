import React from "react";

import {
  Container,
  Box,
  Button,
  // Customizable Area Start
  Modal,
  Card,
  CardContent,
  IconButton,
  Typography,
  Divider,
  styled,
  TextField,
  InputAdornment,
  Avatar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { defaultAvatar } from "../../../blocks/user-profile-basic/src/assets";
const configJSON = require("./config");
import { goToPrevPage } from "../../../components/src/Utilities";
import { edit } from "../../../blocks/postcreation/src/assets";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import CustomToaster from "../../../components/src/CustomToaster";
import CustomButton from "../../../components/src/CustomButton";


const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});


const StyledBox = styled(Box)({
  "&.disclaimer-container": {
    margin: "35px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "@media (max-width: 1050px)": {
      flexDirection: "column",
    },
  },

  "&.btn-container": {
    display: "flex",
    justifyContent: "space-between",
    width: "425px",
    "@media (max-width: 1050px)": {
      marginTop: "25px",
    },

    "@media (max-width: 600px)": {
      width: "325px"
    },

  },
});

const StyledTextfield = styled(TextField)({
  width: "90%",
  color: "#475569",
  "& .MuiOutlinedInput-notchedOutline": {
    fontFamily: "Montserrat !important",
    border: "none",
  },
  "& .custom-placeholder::placeholder": {
      color: "#475569",
      fontWeight: 400
  },
});



const StyledCustomButton = styled(CustomButton)({
  "&.btn-Draft": {
    width: "196px",
    fontSize: "20px",
    height: "56px",
    fontWeight: 700,
    "@media (max-width: 960px)": {
      fontSize: "18px",
      width: "180px",
      height: "50px",
      marginInline: "10px"
    },
    "@media (max-width: 500px)": {
      fontSize: "16px",
      width: "160px",
      height: "44px",
    },
  },

  "&.btn-Post": {
    width: "196px",
    fontSize: "20px",
    height: "56px",
    fontWeight: 700,
    "@media (max-width: 960px)": {
      fontSize: "18px",
      width: "180px",
      height: "50px",
      marginInline: "10px"
    },
    "@media (max-width: 500px)": {
      fontSize: "16px",
      width: "160px",
      height: "44px",
    },
  }
})



import { attachment, retweet } from "./assets";
// Customizable Area End

import RepostController, { Props } from "./RepostController";

export default class Repost extends RepostController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <NavigationMenu navigation={this.props.navigation} id={"1"} />
        <Container
          style={webStyles.containerStyle}
          data-test-id="postcreation-container"
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              data-test-id = "btn-back"
              style={webStyles.IconBtnStyle}
              onClick={() => goToPrevPage()}
            >
              <ArrowBackIcon htmlColor="#fff" />
            </IconButton>
            <Typography style={webStyles.titleStyle}>
              {configJSON.repostTitle}
            </Typography>
          </Box>
          <Box
            style={{
              marginTop: "40px",
              display: "flex",
            }}
          >
            <Avatar
              alt="profile"
              src={
                this.state.currUserDetails?.attributes?.profile_image ??
                defaultAvatar
              }
              style={{
                height: "44px",
                width: "44px",
                border: "1px solid #F0E5FF",
              }}
            />
            <Box style={{ marginLeft: "20px" }}>
              <Typography
                style={{ color: "#0F172A", fontSize: "18px", fontWeight: 600 }}
              >
                {this.state.currUserDetails?.attributes?.email.split("@")[0]}
              </Typography>
              <Typography style={{ color: "#475569", fontSize: "14px" }}>
                {" "}
                <img src={retweet} alt="" /> Repost
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              height: "fit-content",
              borderRadius: "12px",
              marginTop: "40px",
              width: "100%",
              background: "#F5F1EF",
              position: "relative",
            }}
          >
            <ThemeProvider theme={theme}>
              <StyledTextfield
                data-test-id="title-field"
                variant="outlined"
                placeholder="Add a Title"
                multiline
                minRows={1}
                maxRows={1}
                InputProps={{
                  classes: {
                    input: "custom-placeholder",
                  },
                  startAdornment: (
                    <InputAdornment
                      position="start"
                    >
                      <img
                        src={edit}
                        alt="start-adornment"
                        style={{ width: 24, height: 24 }}
                      />
                    </InputAdornment>
                  ),
                }}
                
                onChange={(event) =>
                  this.handleTextFieldChange(event, configJSON.title)
                }
              />
              <Divider
                style={{ backgroundColor: "#CBD5E1", marginBottom: "10px" }}
              />
              <StyledTextfield
                data-test-id="description-field"
                variant="outlined"
                placeholder="What do you want to say?"
                multiline
                minRows={4}
                maxRows={4}
                onChange={(event) =>
                  this.handleTextFieldChange(event, configJSON.description)
                }
                InputProps={{
                  classes: {
                    input: "custom-placeholder",
                  },
                }}
              />
            </ThemeProvider>
            <Divider
              style={{ backgroundColor: "#CBD5E1", marginBottom: "0px" }}
            />
            <Box>
              <Box
                style={{
                  padding: "20px 0px",
                  margin: "0px 25px",
                  display: "flex",
                }}
              >
                <Avatar
                  alt="profile"
                  src={
                    this.state.postDetails?.attributes?.post_by_user?.profile_image ??
                    defaultAvatar
                  }
                  style={{
                    height: "44px",
                    width: "44px",
                    border: "1px solid #F0E5FF",
                  }}
                />
                <Box style={{ marginLeft: "20px" }}>
                  <Typography
                    style={{
                      color: "#0F172A",
                      fontSize: "18px",
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {
                      this.state.postDetails?.attributes?.post_by_user?.email?.split(
                        "@"
                      )[0]
                    }{" "}
                    <span style={{ marginLeft: "5px" }}>•</span>
                    <span
                      style={{
                        color: "#64748B",
                        fontSize: "16px",
                        marginLeft: "5px",
                      }}
                    >
                      {this.state.postDetails?.attributes?.created_at}
                    </span>{" "}
                  </Typography>
                  <Typography style={{ color: "#0F172A", fontSize: "16px" }}>
                    {this.state.postDetails?.attributes?.title ??
                      this.state.postDetails?.attributes?.body}
                  </Typography>
                  {this.state.postDetails?.attributes?.images_and_videos && (
                    <>
                      {this.getMediaType(
                        this.state.postDetails?.attributes?.images_and_videos[0]
                          ?.url
                      ) === "image" ? (
                        <img
                          src={
                            this.state.postDetails?.attributes
                              ?.images_and_videos[0]?.url
                          }
                          alt=""
                          style={{
                            height: "170px",
                            width: "245px",
                            borderRadius: "12px",
                            marginTop: "20px",
                          }}
                        />
                      ) : (
                          <video controls loop autoPlay style={{
                              height: "170px",
                              width: "245px",
                              borderRadius: "12px",
                              marginTop: "20px",
                              objectFit: "cover"
                            }}>
                            <source src={this.state.postDetails?.attributes?.images_and_videos[0]?.url} />
                          </video>
                      )}

                      <Typography
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#64748B",
                          fontSize: "16px",
                          fontWeight: 400,
                          marginTop: "10px",
                        }}
                      >
                        <img
                          src={attachment}
                          style={{ marginRight: "5px" }}
                          alt=""
                        />{" "}
                        {this.getFileName(
                          this.state.postDetails?.attributes
                            ?.images_and_videos[0]?.url
                        )}
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <StyledBox className="disclaimer-container">
            <StyledBox className="btn-container">
              <StyledCustomButton
                variant="outlined"
                color="primary"
                fullWidth
                dataTestId="btn-back"
                btnText={"Cancel"}
                className="btn-Draft"
                onClick={() => goToPrevPage()}
              />
              <StyledCustomButton
                variant="contained"
                color="primary"
                fullWidth
                className="btn-Post"
                dataTestId="btn-submit"
                btnText={"Post"}
                onClick={() =>this.makeRepost()}
              />
            </StyledBox>
          </StyledBox>
          <CustomToaster />
        </Container>
      </React.Fragment>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles : { [key: string]: React.CSSProperties } = {
  containerStyle: { marginTop: "50px" },

  IconBtnStyle: {
    height: "50px",
    width: "50px",
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    borderRadius: "8px",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },
  titleStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#282627",
  },
  subLabelStyle: {
    color: "#64748B",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    margin: "25px 0",
  },
  contactLabelSyle: {
    color: "#334155",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
  },
  btnSubmitStyle: {
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color: "#fff",
    fontSize: "20px",
    fontWeight: 700,
    display: "inline-block",
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    borderRadius: "8px",
    height: "56px",
  },
};
// Customizable Area End
