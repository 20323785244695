import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { callApi } from "../../../components/src/Utilities";
import { ChangeEvent } from "react";
import { toast } from "react-hot-toast";

interface CommentResponse {
  data: CommentData[];
}

interface CommentData {
  id: string;
  type: string;
  attributes: CommentAttributes;
}

interface CommentAttributes {
  id: number;
  account_id: number;
  commentable_id: number;
  commentable_type: string;
  comment: string;
  created_at: string;
  updated_at: string;
  like_count: number;
  comment_by: CommentAuthor;
  delete_icon_flag: boolean;
}

interface CommentAuthor {
  id: number;
  name: string;
  email: string;
  profile_image: string;
}


// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  postId?: number;
  commentCount?: number
  title?: string
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  commentData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  comment: string;
  commentsArray: any;
  replyCommentId: any;
  isLoading: boolean;
  userComment: string;
  viewAllClicked: boolean;
  commentList: CommentData[];
  commentCount: number;
  isDelete: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CommentController extends BlockComponent<Props, S, SS> {
  apiCommentItemCallId: string = "";
  commentApiCallId: string = "";
  createCommentId: string = "";
  likeCommentId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationScreenNameMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationRaiseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      
    ];

    this.state = {
      commentData: [],
      errorMsg: "",
      token: "",
      loading: false,
      comment: "",
      commentsArray: [],
      replyCommentId: null,
      isLoading: false,
      userComment: "",
      viewAllClicked: false,
      commentList: [],
      commentCount: 0,
      isDelete: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    } else {
      this.getToken();
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.setState({
      isLoading: false
    })
    if (errorReponse || responseJson?.errors || responseJson?.error) {
      this.apiFailureCallBacks(responseJson);
    }

    else if (responseJson && !responseJson?.errors) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  getCommentData(): boolean {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: this.state.token,
    };
    console.log("header  ", JSON.stringify(header));
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);

    this.apiCommentItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  setComment = (comment: string) => {
    this.setState({ comment });
  };

  createComment = (id?: any, postId?: any) => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: this.state.token,
    };

    const data = {
      attributes: {
        post_id: postId ? postId : 1,
        comment: this.state.comment,
      },
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  likeChildComments = (commentId: any) => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: this.state.token,
    };
    const data = {
      attributes: {
        comment_id: commentId,
      },
    };
    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likeCommentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCommentText(comment: any) {
    try {
      return JSON.parse(comment.replace("=>", ":"))?.text;
    } catch {
      return comment;
    }
  }

  getAllCommentsId: string = "";
  postUserCommentId: string = "";
  deleteCommentId: string = "";
  postLikeCommentId: string = "";

  getAllCommentsbyId = async () => {
    const authToken = await getStorageData("token")
    this.setState({ isLoading: true });

    this.getAllCommentsId = callApi({
      contentType: configJSON.commentsContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${(configJSON.commentsByPostEndpoint as string)}?post_id=${this.props.postId}`,
      headers: { "token": authToken},
    }, runEngine);
  }

    
  postUserComment = async () => {
    const authToken = await getStorageData("token");
    this.setState({ isLoading: true });
    const { userComment } = this.state;
    const { postId } = this.props;

    const payload = {
      comment: {
        comment: userComment,
        commentable_type: configJSON.postCommentType,
        commentable_id: postId,
      },
    };

    this.postUserCommentId = callApi({
      contentType: configJSON.commentsContentType,
      method: configJSON.httpPostMethod,
      endPoint: `${(configJSON.postCommentEndpoint as string)}`,
      body: payload,
      headers: { "token": authToken},
    }, runEngine);

  }


  toggleViewAll = () => {
    this.setState({
      viewAllClicked: !this.state.viewAllClicked
    })
  }

  deleteComment = async (commentId: number) => {
    const authToken = await getStorageData("token");

    this.deleteCommentId = callApi({
      contentType: configJSON.commentsContentType,
      method: configJSON.httpDeleteMethod,
      endPoint: `${(configJSON.deleteCommentEndpoint as string)}/${commentId}`,
      headers: { "token": authToken},
    }, runEngine);
  }


  handleCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      userComment: event.target.value
    })
  }


  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: CommentResponse | any) => {
    if (apiRequestCallId === this.postUserCommentId){
      this.setState((prevState) => ({
        userComment: "",
        commentCount : prevState.commentCount + 1
      }))
      if (responseJson?.meta?.success){
        toast.success("Comment posted successfully!");
        this.getAllCommentsbyId()
      }
    }
    if (apiRequestCallId === this.getAllCommentsId) {
      this.setState({
        commentList: responseJson?.data
      })
    }

    if (apiRequestCallId === this.deleteCommentId){
      if (responseJson?.message === "Comment deleted."){
        toast.success("Comment deleted successfully");
        this.setState((prevState) => ({
          userComment: "",
          commentCount : prevState.commentCount - 1
        }))
        this.getAllCommentsbyId()
      }
    }

    if (apiRequestCallId === this.postLikeCommentId){
      if (responseJson?.data){
        this.getAllCommentsbyId()
      }
    }

  }

  apiFailureCallBacks = (responseJson: {errors: [{token: string}]}) => {
    if(responseJson?.errors && responseJson.errors[0]?.token === configJSON.tokenExpireMsg || responseJson.errors[0]?.token === "Invalid token"){
      toast.error(configJSON.unauthorizedMsg);
      this.navigateToPath("EmailAccountLoginBlock");
      return;
    }
    toast.error(configJSON.fetchErrorMsg);
  }


  navigateToPath = (pathName: string, param?: string | number) => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pathName
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), param);
    this.send(navigationMessage);
  };

  handleDelete = (id: number) => {
    this.setState({isDelete: !this.state.isDelete})

    setTimeout(() => {
      if(this.state.isDelete) {
        this.deleteComment(id)
        this.setState({ isDelete: false });
      }
    }, 3000); 
  }

  closeDelete = () => {
    this.setState({isDelete: !this.state.isDelete})
  }

  handleLike = async(id: string) => {
    const authToken = await getStorageData("token");
    const payload =  {
      "data": {
        "attributes": {
          "likeable_type": "BxBlockComments::Comment",
          "likeable_id": id
        },
      }
    };

    this.postLikeCommentId = callApi({
      contentType: configJSON.commentsContentType,
      method: configJSON.httpPostMethod,
      endPoint: '/bx_block_like/likes',
      body: payload,
      headers: { "token": authToken},
    }, runEngine);
  }
  // Customizable Area End
}
