import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import CustomButton from "./CustomButton";
import {CheckCircleOutline} from "@material-ui/icons";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  isEdit: boolean;
}

const CustomStatusModal = (props: Props) => {
  const { isOpen, handleClose, isEdit } = props;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth="xs"
    >
      <DialogContent>

        <Typography
          style={{
            fontSize: "16px",
            color: "#0F172A",
            display: "flex",
            justifyContent: "center",
            margin: "25px 0px",
          }}
        >
          <CheckCircleOutline htmlColor="green" fontSize="large" style={{fontSize: "60px"}}/>
        </Typography>
        <Typography
          style={{
            fontSize: "16px",
            color: "#0F172A",
            display: "flex",
            justifyContent: "center",
            fontWeight: 600
          }}
        >
          {isEdit ? "Post updated successfully !" : "Post created successfully !"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CustomButton
          variant="contained"
          onClick={() => handleClose()}
          color="primary"
          btnText="Done"
          dataTestId="btn-submit"
          style={{
            margin: "10px 15px",
            height: "56px",
            fontSize: "16px",
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CustomStatusModal;
