import React from "react";

// Customizable Area Start
import { Box, IconButton, styled, Typography} from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import { arrowIcon, playIcon, shareIcon, shopifyDemo } from "./assets";
import CustomSkeleton from "../../../components/src/CustomSkeleton";
// Customizable Area End

import ApiIntegrationController, {
  Props,
  configJSON,
} from "./ApiIntegrationController";

// Customizable Area Start
export const StyledCarousel = styled(Carousel)({
  "& .control-arrow": {
    background: "none !important",
    opacity: "1 !important",
  },
  "& .image-container": {
    position: "relative",
    display: "inline-block",
    width: "865px",
    borderRadius: "8px 8px 0 0",
    overflow: "hidden",
  },

  "& .gradient-overlay": {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    height: "50%",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.8) 100%)",
    pointerEvents: "none",
    zIndex: 1
  },

  "& .dot": {
    height: "10px !important",
    width: "10px !important",
    margin: "0px 4px !important"
  },

  "& .carousel-slider": {
    width: "865px !important",
    height: "360px"
  },
});
// Customizable Area End

export default class ApiIntegration extends ApiIntegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyles.containerStyle} data-test-id="productWrapper">
        {this.state.isLoading ? <CustomSkeleton
          variant="rect"
          style={{
            width: "865px",
            height: "360px",
            borderRadius: "8px"
          }}
        /> : 
        <StyledCarousel onChange={this.handleCarouselChange} className="" showThumbs={false} showArrows={this.state.shopifyProducts?.length > 1} showStatus={false} showIndicators={this.state.shopifyProducts?.length > 1}>
          {
            this.state.shopifyProducts?.map((product) => {
              return (
                <div className="image-container" key={product?.id} data-test-id="productImageContainer">
                  <img src={product?.images.length > 0 ? product?.images[0]?.src : shopifyDemo} height={360} width={860} style={{ width: "865px !important", height: "360px !important", borderRadius: "8px 8px 0 0", 
                    objectFit:"contain"
                    }} alt="" />
                  <div className="gradient-overlay"></div>
                  <Box style={webStyles.productContainer}>
                    <Typography data-test-id="productTitle" style={webStyles.productName}>{product?.title}</Typography>
                  </Box>
                </div>
              )
            })
          }
        </StyledCarousel>}
        <Typography style={webStyles.shopnowStyle} data-test-id="shopNow" onClick={this.shopNowClick}>
          <span style={webStyles.txtStyle}>Shop now</span>
          <img src={arrowIcon} alt="" />
        </Typography>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles : { [key: string]: React.CSSProperties } = {
  containerStyle: { marginBottom: "50px", position: "relative", marginTop: "25px", width: "865px" },
  shopnowStyle: {
    backgroundColor: "#925B5B",
    color: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "65px",
    paddingInline: "25px",
    borderRadius: "0 0 8px 8px",
    marginTop: "-5px",
    cursor: "pointer"
  },

  IconBtnStyle: {
    marginRight: "10px",
    border: "1px solid #fff",
    height: "55px",
    width: "55px",
    borderRadius: "8px"
  },

  txtStyle: {
    fontSize: "20px",
    fontWeight: 600
  },
  productName: {
    color: "white",
    fontSize: "29px",
    fontWeight: 700,
    width: "40%",
    textAlign: "left"
  },
  productContainer: {
    position: "absolute",
    left: "35px",
    bottom: "50px",
    width: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 9
  }
};
// Customizable Area End
