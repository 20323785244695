import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-hot-toast";
import { callApi, goToPrevPage } from "../../../components/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  currActiveTab: string;
  oldPwd: string;
  newPwd: string;
  isChangePwdActive: boolean;
  isPrivacyActive: boolean;
  isLoading: boolean;
  isConfirmationModalOpen: boolean;
  isLogoutModalOpen: boolean;
  showPassword: boolean;
  showNewPassword: boolean;
  hasUpperCase: boolean;
  hasSpecialChar: boolean;
  hasNumber: boolean;
  hasMinLength: boolean;
  isPasswordInvalid: boolean;
  isChecked: boolean;
  selectedPrivacySettings: ("Everyone" | "Followers" | "Only me" | "none") []
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  privacySettings = [configJSON.postsQuestions, configJSON.photosAndVideosQuestions, configJSON.repostQuestions, configJSON.profileQuestions];
  options = [configJSON.everyone, configJSON.followers, configJSON.onlyMe]
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      currActiveTab: configJSON.accountTab,
      oldPwd: "",
      newPwd: "",
      isChangePwdActive: false,
      isPrivacyActive: false,
      isLoading: false,
      isConfirmationModalOpen: false,
      isLogoutModalOpen: false,
      showPassword: false,
      showNewPassword: false,
      hasUpperCase: false,
      hasSpecialChar: false,
      hasNumber: false,
      hasMinLength: false,
      isChecked: false,
      isPasswordInvalid: true,
      selectedPrivacySettings: ["none", "none", "none", "none"]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.setState({
      isLoading: false
    })
    if(apiRequestCallId === this.deleteAccountId){
      if(responseJson){
        toast.success("Account deleted successfully")
        this.navigateToPath("EmailAccountLoginBlock");
      }
    }
    if (errorReponse || responseJson?.errors || responseJson?.error) {
      this.apiFailureCallBacks(responseJson);
    }

    else if (responseJson && !responseJson?.errors) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start


  destroyTokenId: string = "";
  deleteAccountId: string = "";
  changePwdId: string = "";


  handleTabChange = (tabName: string) => {
    this.setState({
      currActiveTab: tabName
    }, () => {
      if(this.state.currActiveTab === configJSON.actionsTab) {
        this.setState({isChangePwdActive: false, isPrivacyActive: false})
      }
    }) 
  }

  handleCheckboxChange() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  navigateToPath = (pathName: string) => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pathName
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigationMessage);
  };

  logoutUser = async () => {
    this.destroyToken();
    await removeStorageData('authToken');
    await removeStorageData('token');
  }


  destroyToken = async () => {
    const authToken = await getStorageData("token");
    this.destroyTokenId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.methodTypes.HTTP_DELETE,
      endPoint: `${(configJSON.endpoints.DESTROY_TOKEN as string)}`,
      headers: { "token": authToken},
    }, runEngine);
  }


  deleteAccount = async () => {
    const authToken = await getStorageData("token");
    this.deleteAccountId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.methodTypes.HTTP_DELETE,
      endPoint: `${(configJSON.endpoints.DELETE_ACCOUNT as string)}`,
      headers: { "token": authToken},
    }, runEngine);
  }


  changePwd = async () => {
    const { oldPwd, newPwd } = this.state;
    this.setState({ isLoading: true })
    const authToken = await getStorageData("token")

    const payload = {
      old_password: oldPwd,
      new_password: newPwd
    }

    this.changePwdId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.methodTypes.HTTP_PUT,
      endPoint: `${(configJSON.endpoints.CHANGE_PWD as string)}`,
      body: payload,
      headers: { "token": authToken},
    }, runEngine);
  }


  toggleConfirmationModal = (toggle: boolean) => {
    this.setState({ isConfirmationModalOpen: toggle })
  }

  toggleLogoutModal = (toggle: boolean) => {
    this.setState({ isLogoutModalOpen: toggle })
  }
  toggleDeleteConfirmationModal = (toggle: boolean) => {
    this.setState({ isConfirmationModalOpen: toggle })
  }
  toggleChangePassword = (toggle: boolean) => {
    this.setState({
      isChangePwdActive: toggle,
      oldPwd: "",
      newPwd: ""
    })
  }
  togglePrivacySetting = (toggle: boolean) => {
    this.setState({
      isPrivacyActive: toggle
    })
  }

  handleOldPwdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      oldPwd: event.target.value
    }, () => this.validatePassword(this.state.oldPwd))
  }

  handleNewPwdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      newPwd: event.target.value
    }, () => this.validatePassword(this.state.newPwd))
  }

  handleTogglePasswordVisibility = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleToggleNewPasswordVisibility = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };

  validatePassword = (password: string) => {
    this.setState({
      hasUpperCase: configJSON.upperCaseLetter.test(password),
      hasSpecialChar: configJSON.specialLetter.test(password),
      hasNumber: configJSON.hasNum.test(password),
      hasMinLength: password.length >= 8,
      isPasswordInvalid:
        !configJSON.upperCaseLetter.test(password) ||
        !configJSON.specialLetter.test(password) ||
        !configJSON.hasNum.test(password) ||
        !this.state.oldPwd ||
        !this.state.newPwd ||
        password.length < 8,
    });
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: {message: string} & {message: {success: string}}) => {
    if (apiRequestCallId === this.changePwdId){
      if (responseJson.message === configJSON.pwdsuccessMsg) {
        toast.success(configJSON.pwdsuccessMsg);
        this.setState({
          isChangePwdActive: false
        })
      }
      else if (responseJson.message){
        toast.error("Your old password is not correct")
      }
    }

    if (apiRequestCallId === this.destroyTokenId){
      if (responseJson?.message?.success) {
        this.navigateToPath(configJSON.loginBlock);
        toast.success(configJSON.loggedOutMsg);
      }
    }

  }

  apiFailureCallBacks = (responseJson: {errors: [{token: string}]}) => {
    if(responseJson?.errors && responseJson.errors[0]?.token === configJSON.tokenExpireMsg || responseJson.errors[0]?.token === "Invalid token"){
      toast.error(configJSON.unauthorizedMsg);
      this.navigateToPath("EmailAccountLoginBlock");
      return;
    }
    toast.error(configJSON.fetchErrorMsg);
  }
  
  handleCancelClick = () => {
    if (this.state.isChangePwdActive){
      this.toggleChangePassword(false);
    } else if (this.state.isPrivacyActive){
      this.togglePrivacySetting(false);
    } else {
      goToPrevPage();
    }
  }

  handleCheckboxChange2 = (i: number, item: "Everyone" | "Followers" | "Only me" | "none") => {
    const updatedSettings = [...this.state.selectedPrivacySettings];
    updatedSettings[i] = item;
    this.setState({ selectedPrivacySettings: updatedSettings });
  }

  // Customizable Area End
}
