import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-hot-toast";
import { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  setSelectedFiles: (mediaData: File[]) => void;
  selectedFiles: File[];
  setLoader: (toggle: boolean) => void;
  isDisable: boolean
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Uploadmedia3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileInputRef = createRef<HTMLInputElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start


  handleButtonClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };



  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.setLoader(true);
    const files = event.target.files;
    const { selectedFiles } = this.props;
  
    if (files) {
      const fileArray = Array.from(files);
  
      const validFiles = this.filterValidFiles(fileArray);
      const nonDuplicateFiles = this.filterNonDuplicateFiles(validFiles, selectedFiles);
  
      if (nonDuplicateFiles.length + selectedFiles.length > configJSON.maxFileAllowed) {
        toast.error(configJSON.maxFileValidation);
        this.props.setLoader(false);
      } else if (nonDuplicateFiles.length === 0 && validFiles.length > 0) {
        toast.error(configJSON.duplicateFileMsg);
        this.props.setLoader(false);
      } else if (nonDuplicateFiles.length > 0) {
        this.props.setSelectedFiles([...selectedFiles, ...nonDuplicateFiles]);
      }
      event.target.value = "";
    }
  };
  
  filterValidFiles = (fileArray: File[]) => {
    return fileArray.filter((file) => {
      const isValidType =
        file.type === "image/jpeg" || file.type === "image/png" || file.type === "video/mp4";
  
      if (!isValidType) {
        toast.error(configJSON.invalidFileTypeMsg);
        this.props.setLoader(false);
        return false;
      }
  
      let isValidSize = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isValidSize = file.size <= 30 * 1024 * 1024;
      } else if (file.type === "video/mp4") {
        isValidSize = file.size <= 100 * 1024 * 1024;
      }
  
      if (!isValidSize) {
        const sizeLimit = file.type === "video/mp4" ? "100MB" : "30MB";
        toast.error(`File ${file.name} exceeds the ${sizeLimit} size limit.`);
        this.props.setLoader(false);
      }
  
      return isValidType && isValidSize;
    });
  };
  
  filterNonDuplicateFiles = (validFiles: File[], selectedFiles: File[]) => {
    return validFiles.filter((file) => {
      return !selectedFiles.some(
        (selectedFile) => selectedFile.name === file.name && selectedFile.size === file.size
      );
    });
  };

  // Customizable Area End
}
