// Customizable Area Start
export const imagIcon = require("../assets/gallery.png");
export const reportIcon = require("../assets/report_icon.svg");
export const unfollowIcon = require("../assets/unfollow_icon.svg");
export const viewProfile = require("../assets/viewprofile_icon.svg");
export const followIcon = require("../assets/followIcon.svg");
export const archiveIcon = require("../assets/archive_icon.svg");
export const editIcon = require("../assets/edit.png");
export const deleteIcon = require("../assets/delete.png");
export const viewIcon = require("../assets/view-icon.png");
// Customizable Area End
