import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import CustomButton from "./CustomButton";
import CloseIcon from "@material-ui/icons/Close";

interface Props {
  isOpen: boolean;
  handleClose: (toggle: boolean) => void;
  successCallback: () => void,
  title?: string,
}

const DeleteConfirmationModal = (props: Props) => {
  const { isOpen, handleClose, successCallback, title } = props;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      fullWidth
      maxWidth="sm"
      style={{borderRadius: "12px"}}
    >
     {title !== "comment" ? <>
      <DialogTitle
        id="customized-dialog-title"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <IconButton
          aria-label="close"
          onClick={() => handleClose(false)}
          style={{ position: "absolute", top: 5, right: 5 }}
        >
          <CloseIcon htmlColor="#334155" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          style={{
            fontSize: "24px",
            color: "#0F172A",
            display: "flex",
            justifyContent: "flex-start",
            margin: "25px 0px",
            fontWeight: 600
          }}
        >
          Are you sure you want to delete account?
        </Typography>
        <Typography
          style={{
            fontSize: "18px",
            color: "#0F172A",
            display: "flex",
            justifyContent: "flex-start",
            margin: "25px 0px",
            fontWeight: 400
          }}
        >
          This action can’t be undone and all the data stored in this account won’t be accessible anymore.
        </Typography>
      </DialogContent>
      <Divider style={{ backgroundColor: "#E2E8F0", margin: "10px 0px" }} />
      <DialogActions>
      <CustomButton
          variant="outlined"
          onClick={() => {handleClose(false)}}
          color="primary"
          btnText="Keep"
          style={{
            margin: "10px 15px",
            height: "56px",
            fontSize: "16px",
            width: "120px"
          }}
        />
        <CustomButton
          variant="contained"
          onClick={() => {successCallback(); handleClose(false)}}
          color="primary"
          data-test-id="delete"
          btnText="Delete"
          style={{
            margin: "10px 15px",
            height: "56px",
            fontSize: "16px",
            width: "120px"
          }}
        />
      </DialogActions>
     </> : 
     <DialogContent style={{width: '541px', height: "56px", padding: '0px', display: 'flex', justifyContent: "center", alignItems: "center", gap: '20px'}}>
      <Typography style={{fontSize: '20px', fontWeight: 600, lineHeight: "24px", fontFamily: "Montserrat"}}>Your comment is deleted!</Typography>
      <Button onClick={successCallback} style={{width: "77px", height: "32px", background: "#925B5B", padding: '10px 16px', color: "white", borderRadius: "8px", textTransform: 'none', fontSize: '16px', fontWeight: 600, lineHeight: '24px', fontFamily: "Montserrat"}}>
        Undo
      </Button>
     </DialogContent>
     }
    </Dialog>
  );
};

export default DeleteConfirmationModal;
